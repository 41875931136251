




































































































































































































h1 {
  margin: 0;
  padding-top: 20px;
  color: var(--color-text-primary);
}

.main-container{
  height: 100%;
  width: 100%;
}

.title-container{
  margin-left: 10px;
}

.empty-cart-container{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  text-align: center;
}

.quantity-wrapper{
  display: flex;
  align-items: center;
}

.product-quantity-container{
  display: flex;
  align-items: center;
}

.empty-cart-text,
.add-products-text{
  color: grey;
}

.empty-cart-text{
  font-weight: 500;
}

.add-products-text{
  font-size: 12px;
}

.continue-shopping-link{
  background-color: #0aadd9;
  color: white;
  border-radius: 25px;
  padding: 10px;
}

.continue-shopping-container{
  margin-top: 20px;
}

.cart-products-container{
  margin-left: 10px;
}

.product{
  border: 1px solid grey;
  width: 80%;
  margin-top: 15px;
  border-radius: 10px;
  padding: 5px;
}

.product * {
  color: var(--color-text-primary);
}

.manage-product-quantity-btn{
  background-color: transparent;
  color: #0aadd9;
  border: none;
  cursor: pointer;
}

.remove-product{
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background-color: #0aadd9;
  border-radius: 15px;
  color: white;
  padding: 10px;
}

.cart-price-container * {
  color: var(--color-text-primary);
}

.cart-price-container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-price-container .price{
  font-size: 25px;
  font-weight: bold;
}

.btns-container{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.back-btn{
  cursor: pointer;
  border: none;
  background-color: #0aadd9;
  border-radius: 15px;
  color: white;
  padding: 10px;
  width: 100px;
}

.buy-btn{
  cursor: pointer;
  border: none;
  font-size: 20px;
  background-color: orange;
  color: black;
  border-radius: 15px;
  padding: 10px;
  width: 150px;
}

.popup-style-container{
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 1001;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5);
}
.popup-modal{
  position: absolute;
  top: 30%;
  left: 30%;
  z-index: 1002;
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-color: black;
  padding: 10px;
  width: 585px;
  height: 230px;
  animation: fadeIn 1s;
  display: inline-flex;
}
.buttons-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20%;
}


