







































@use "@material/button";
.mdc-button {
  @include button.shape-radius(50% , 0, 0);
  @include button.horizontal-padding(40px)	
}	
.error-report .mdc-button {
  @include button.horizontal-padding(0px);
  @include button.container-fill-color(#CACDD8);
  @include button.icon-color(#545A6F);
  @include button.shape-radius(50% , 0, 0);
  margin-left: 10px;
  width: 20px;
  .mdc-button__icon {
    margin: 0;
  }
}
#stock-comments .mdc-button {
  @include button.horizontal-padding(0px);
  @include button.container-fill-color(#CACDD8);
  @include button.icon-color(#545A6F);
  @include button.shape-radius(50% , 0, 0);
  margin-left: 10px;
  width: 20px;
  .mdc-button__icon {
    margin: 0;
  }
}
#comment-writer .mdc-button {
  @include button.horizontal-padding(0px);
  @include button.container-fill-color(#CACDD8);
  @include button.icon-color(#545A6F);
  @include button.shape-radius(50% , 0, 0);
  margin-left: 10px;
  width: 20px;
  .mdc-button__icon {
    margin: 0;
  }
}
#chart-options-stock .mdc-button {
  @include button.horizontal-padding(0px);
  @include button.container-fill-color(transparent);
  @include button.icon-color(#545A6F);
  @include button.shape-radius(0% , 0, 0);
  margin-left: 0px;
  margin-bottom: 5px;
  width: 150px;
  color:black;
  border-width: 2px;
  border-style: solid;
  border-color: #cccc; 
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  box-shadow: 0 0 0 0 !important;
  border-style: groove groove none groove;   
  .mdc-button__icon {
    margin: 0;
  }
}
#create-portfolio .mdc-button {
  @include button.horizontal-padding(0px);
  @include button.container-fill-color(transparent);
  @include button.icon-color(#545A6F);
  @include button.shape-radius(0% , 0, 0);
  margin-left: 0px;
  margin-bottom: 5px;
  width: 150px;
  color:black;
  border-width: 2px;
  border-style: solid;
  border-color: #cccc; 
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  box-shadow: 0 0 0 0 !important;
  border-style: groove groove none groove;   
  .mdc-button__icon {
    margin: 0;
  }
}

#registro{
  .key-favorite-button{
    box-sizing: border-box;
    margin-left: 5px;

    /* Auto layout */

    justify-content: center;
    padding: 3px 46px;
    gap: 39px;

    width: 90px;
    height: 26px;

    background: rgba(227, 155, 15, 0.25);
    /* Tema Claro/05. Otros/Advertencia */

    border: 1px solid #E39B0F;
    border-radius: 20px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;

    span{
      width: 62px;
      height: 20px;

      /* Body 2 */

      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      /* identical to box height, or 143% */

      display: flex;
      align-items: center;
      letter-spacing: 0.25px;

      /* Tema Claro/04. Typography/On background */

      color: #000010;
    }
  }
  .key-cartera-button{
    margin-left: 5px;
    box-sizing: border-box;

    /* Auto layout */

    justify-content: center;
    padding: 3px 46px;
    gap: 39px;

    width: 90px;
    height: 26px;

    background: rgba(186, 145, 187, 0.25);
    border: 1px solid #BA91BB;
    border-radius: 20px;

    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;

    span{
      width: 62px;
      height: 20px;

      /* Body 2 */

      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      /* identical to box height, or 143% */

      display: flex;
      align-items: center;
      letter-spacing: 0.25px;

      /* Tema Claro/04. Typography/On background */

      color: #000010;

    }
  }
  .key-notas-button{
    margin-left: 5px;
    box-sizing: border-box;

    /* Auto layout */
    justify-content: center;
    padding: 3px 46px;
    gap: 39px;

    width: 90px;
    height: 26px;

    background: rgba(73, 76, 90, 0.25);
    /* Tema Claro/02. Primary/700-Secundary */

    border: 1px solid #20202B;
    border-radius: 20px;

    /* Inside auto layout */

    span{
      width: 62px;
      height: 20px;

      /* Body 2 */

      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      /* identical to box height, or 143% */

      display: flex;
      align-items: center;
      letter-spacing: 0.25px;

      /* Tema Claro/04. Typography/On background */

      color: #000010;


      /* Inside auto layout */

    }
  }
  .key-alarm-button{
    margin-left: 5px;
    box-sizing: border-box;

    /* Auto layout */
    justify-content: center;
    padding: 3px 46px;
    gap: 39px;

    width: 90px;
    height: 26px;

    background: rgba(31, 122, 160, 0.25);
    /* Tema Claro/01. Accent/700 */

    border: 1px solid #1F7AA0;
    border-radius: 20px;

    /* Inside auto layout */

    flex: none;
    order: 3;
    flex-grow: 0;

    span{
      width: 62px;
      height: 20px;

      /* Body 2 */

      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      /* identical to box height, or 143% */

      display: flex;
      align-items: center;
      letter-spacing: 0.25px;

      /* Tema Claro/04. Typography/On background */

      color: #000010;
    }
  }
  .key-alert-button{
    margin-left: 5px;
    box-sizing: border-box;

    /* Auto layout */
    justify-content: center;
    padding: 3px 46px;
    gap: 39px;

    width: 90px;
    height: 26px;

    background: rgba(31, 122, 160, 0.25);
    /* Tema Claro/01. Accent/700 */

    border: 1px solid #1F7AA0;
    border-radius: 20px;

    /* Inside auto layout */

    flex: none;
    order: 3;
    flex-grow: 0;

    span{
      width: 62px;
      height: 20px;

      /* Body 2 */

      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      /* identical to box height, or 143% */

      display: flex;
      align-items: center;
      letter-spacing: 0.25px;

      /* Tema Claro/04. Typography/On background */

      color: #000010;
    }
  }
  .mdc-button{
    box-shadow: 0 0 0 0 !important;
    text-transform: none !important;
  }
  .mdc-button__raised{
    box-shadow: 0 0 0 0 !important; 
  }
  .mdc-button__label{
    font-family: 'Open Sans', sans-serif !important;
    font-style: normal !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    letter-spacing: 0.4px !important;
  }
}

#registry-writer .mdc-button {
  @include button.horizontal-padding(0px);
  @include button.container-fill-color(#CACDD8);
  @include button.icon-color(#545A6F);
  @include button.shape-radius(50% , 0, 0);
  margin-left: 10px;
  width: 20px;
  .mdc-button__icon {
    margin: 0;
  }
}
#alarms{
  .key-cotizacion-button{
    width: 78px;
    font-size: 9px;
    margin-left: 4px;
    padding: 0 37px 0 37px;
    color: white;
    background-color: #20202BFF;
  }
  .key-entrada-button{
    width: 78px;
    font-size: 9px;
    margin-left: 2px;
    padding: 0 37px 0 37px;
    color: black;
    background-color: white;
  }
  .key-distancia-entrada-button{
    width: 78px;
    font-size: 9px;
    margin-left: 2px;
    padding: 0 37px 0 37px;
    color: black;
    background-color: white;
  }
  .key-soporte-button{
    width: 78px;
    font-size: 9px;
    margin-left: 2px;
    padding: 0 37px 0 37px;
    color: black;
    background-color: white;
  }
  .key-stop-button{
    width: 78px;
    font-size: 9px;
    margin-left: 2px;
    padding: 0 37px 0 37px;
    color: black;
    background-color: white;
  }
}

